.sidebar .sidebar-brand-wrapper .sidebar-brand.brand-logo-mini img {
    width: calc(90px - 50px);
}

.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
    width: calc(90px - 50px);
}
.userlistss tbody tr td{
   padding: 0px 12px;
}
.form-control:focus {
    color: #fff !important;
    border: 1px solid #998C78 !important;
}
/* .asrt-page-length select{
    background: transparent !important;
    border: 1px solid #413e47 !important;
} */
select {
    cursor: pointer;
}

select option {
    cursor: pointer;
}

input[type="search"]::-webkit-search-cancel-button {
    cursor: pointer !important;
    color: #fff !important;
}

.asrt-pagination li {
    cursor: pointer;
}

.passwordinput .form-control {
    background-color: #0B0B0F;
}

.passwordinput .btn-outline-primary {
    color: #6e7ee5;
    border-color: #494a51;
    padding: 10px 10px;
}

.passwordinput .btn-outline-primary:hover {
    background-color: transparent !important;
}
.footer a{
    color: #96FF00 !important;
}
.asrt-page-length .input-group-addon{
    background: unset !important;
}
.asrt-page-length select{
    color: #fff !important;
}
.input-group-prepend span{
    color: #fff !important;
}
.asrt-table-foot{
    margin-top: 2%;
}
.page-item.disabled .page-link {
    color: #FFEAC8 !important;
    pointer-events: none;
    cursor: auto;
    background-color: #fff0 !important;
    border-color: #FFEAC8 !important;
}
.page-item.disabled .page-link:hover{
    background-color: #95ff0032 !important;
    color: #fff !important;
}
.as-react-table .pagination li:hover a {
    background-color: #96FF00 !important;
    color: #000!important;
}
.page-link {
    background-color: #00000000 !important;
    border: 1px solid #FFEAC8;
}
.page-link input{
    background: unset !important;
    color: #fff !important;
}
.allbtn{
    background: #96FF00;
    border: 1px solid #96FF00;
    padding: 10px 16px;
}
.table thead th{
   line-height: 21px;
}
.allbtnlogout{
    background: red;
    border: 1px solid red;
    padding: 6px 14px;
    color: #fff;
    border-radius: 6px;
}
.allbtnlogout:hover{
    background: transparent;
    border: 1px solid red;
    color: red !important;
}
.allbtns{
    padding: 8px 16px !important; 
}
.css-yt9ioa-option {
    color: black !important; 
    /* background-color: #96FF00 !important; */
}
.css-1n7v3ny-option {
    /* background-color: #96FF00 !important; */
}

.NFT.IDT{
    width: 580px !important;
    max-width: 580px !important;
    word-wrap: break-word !important;
}
.NFT.IDT p{
    line-height: normal;
}

.css-6c09z3-option{
    color:#000 !important;
}
.css-1gj9k8-option{
    background-color: unset !important;
    color: #000 !important;
}
.css-zjsb6u-option{
    background-color: #96FF00 !important;
}

.css-1n7v3ny-option {
    background-color: unset !important;
    color: black !important;
   /* background-color: transparent !important;
   color: black !important; */
}
.css-1pahdxg-control:hover{
    border-color: white !important;
}
.css-9gakcf-option {
    background-color: #96FF00 !important;
}
/* .css-9gakcf-option {
    background-color: transparent !important;
    color: black !important;
} */

.allbtn:hover{
    background: transparent;
    border: 1px solid #96FF00;
    color: #96FF00 !important;
}
.icon.icon-box-success {
    background: rgb(101 182 141 / 24%) !important
}
.icon .icon-item {
    color: #96FF00;
}
.ReactModal__Overlay {
    background-color: #00000091 !important;
}
.ReactModal__Content{
    border: 1px solid #808080a8 !important;
    background: rgb(11 12 15) !important;
    border-radius: 16px !important;
}
.ReactModal__Content h2{
    color: #96FF00 !important;
}
.closebtn{
    position: absolute;
    right: 8px;
    top: 4px;
    font-size: 19px;
    font-weight: 500;
}
.closebtn:hover{
   color: #96FF00;
}
.table-responsive{
    padding-bottom: 2%;
    overflow-x: auto !important;
}
/* .asrt-table-body {
    overflow: auto;
    padding-bottom: 2%;
} */
.table-responsive::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar
{
	width: 8px !important;
    height: 9px !important;
	background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar-thumb
{
    border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #96FF00;
}
.faq .as-react-table{
    padding-bottom: 2%;
    overflow-x: auto !important;
}
.faq .as-react-table::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.faq .as-react-table::-webkit-scrollbar
{
	width: 8px !important;
    height: 9px !important;
	background-color: #F5F5F5;
}

.faq .as-react-table::-webkit-scrollbar-thumb
{
    border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #96FF00;
}
.sidebar .nav .nav-item .nav-link i.menu-arrow:before {
  display: none;
}
.dash-token{
    color: #96FF00 !important;
}
.editservice{
    font-size: 15px;
}
.sidebar .nav.sub-menu .nav-item .nav-link {
    white-space: normal;
    line-height: 20px;
}
th.sortable{
    color: #fff !important;
}
.sidebar .nav .menu-items:nth-child(5n+3) .nav-link .menu-icon i{
    color: #96FF00 !important;
}
.sidebar .nav.sub-menu .nav-item .nav-link:hover{
    color: #96FF00 !important;
}
.vert__adminGreenBtn{
    border: 1px solid #96FF00 !important;
    outline: none !important;
    color: #000 !important;
    background-color: #96FF00 !important;
}
.vert__adminGreenBtn:hover{
    background-color: transparent !important;
    color: #96FF00 !important;
}
.page-link{
    color: #fff !important;
}
.sidebar .nav .nav-item.nav-category .nav-link{
    color: #fff !important;
}
.form-control::placeholder{
    color: #d6d6d6 !important;
}
.form-control {
    border: 1px solid #FFEAC8;
    height: calc(2.25rem + 2px);
    font-weight: normal;
    font-size: 0.875rem;
    padding: 0.625rem 0.6875rem;
    background-color: transparent;
    border-radius: 50px;
    color: #ffffff;
}
.card,.sidebar,.sidebar .sidebar-brand-wrapper{
    background-color: #0B0B0F !important;
}
.auth form .form-group .form-control{
    border-radius: 50px !important;
}
.vert__passInpHolder{
    border: 1px solid #FFEAC8;
    border-radius: 50px !important;
}
.vert__passInpHolder .form-control{
    border: none !important;
}
.vert__pwShower.eyebtns.btn{
    color: #fff !important;
    font-size: 17px !important;
}
.sidebar .nav .nav-item .nav-link{
    color: #fff !important;
}
.sidebar .nav .nav-item:hover .nav-link{
    color: #96FF00 !important;
}
.selectAligner__control{
    background-color: #0B0B0F !important;
    min-width: 250px !important;
    border-color: #FFEAC8 !important;
}
.selectAligner__menu-list{
    background-color: #0B0B0F !important;
    border: 1px solid #FFEAC8 !important;
    border-radius: 5px;
}
.selectAligner__option{
    color: #fff !important;
    font-size: 13px !important;
}
.selectAligner__option:hover{
    background-color: #FFEAC8 !important;
    color: #000 !important;
}
.selectAligner__option--is-selected{
    background-color: #96FF00 !important;
    color: #000 !important;
}
.selectAligner__single-value{
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}
/* th.sortable {
    color: #96FF00 !important;
    font-weight: 600 !important;
} */


@media only screen and (max-width: 767px) {
     .table-responsive::-webkit-scrollbar
{
	width: 6px !important;
    height: 6px !important;
	background-color: #F5F5F5;
}
.faq .as-react-table::-webkit-scrollbar
{
	width: 6px !important;
    height: 6px !important;
	background-color: #F5F5F5;
}
    .table-responsive{
        padding-bottom: 8%;
    }
    .faq .as-react-table{
        padding-bottom: 8%;
    }
    .pagination {
        justify-content: unset !important;
    }
    .pagination li{
        font-size: 11px !important;
        margin-top: 10px;
    }
    .table_filter input{
       margin-top: 14px;
    }
   .asrt-page-length .input-group-text{
       font-size: 11px;
    }
    .asrt-page-length select.form-control {
        padding: 0.4375rem 0.45rem !important;
        width: 53px !important;
        font-size: 12px !important;
    }
    .asrt-table-head .text-right{
        text-align: left !important;
    }
    .asrt-table-foot>div:first-child {
       font-size: 12px;
       margin-top: 6px;
    }
  }
  .navbar.loginnavbar{
    left: 0px !important;
}
.btn-primary:not(.btn-light):not(.btn-secondary):focus, .custom-file .custom-file-label:not(.btn-light):not(.btn-secondary):focus:after, .btn-primary:not(.btn-light):not(.btn-secondary):active, .custom-file .custom-file-label:not(.btn-light):not(.btn-secondary):active:after {
    background: #96FF00 !important;
    border-color: #96FF00 !important;
    color: #000 !important;
}

.referralpages .btnsec{
    display: flex;
    align-items: center;
    margin-top: 8px;
}
.referralpages .add_btnsec{
    margin-left: 15px;
    margin-top: 10px;
}
.referralpages .allbtn{
    width: 100px;
}

@media only screen and (max-width: 767px) {
    .referralpages .add_btnsec{
        margin-left:12px;
    }
    .referralpages .btnsec{
        margin-bottom: 15px;
    }
}
@media (width < 576px){
    .card .card-body{
        padding: 1rem !important;
    }
}
@media (width < 400px){
    .selectAligner__control{
        min-width: 200px !important;
    }
}

/* addon css */
.sidebar-brand.brand-logo{
    display: flex;
}
.sidebar .nav.sub-menu .nav-item .nav-link,.text-muted, .preview-list .preview-item .preview-item-content p .content-category{
    color: #fff !important;
}
.sidebar .nav.sub-menu .nav-item .nav-link.active{
    color: #96FF00 !important;
}
/* end of addon css */
